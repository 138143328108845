export const environment = {
  production: true,
  apiURL: 'https://trainx-api-develop.getcockpit.io',
  auth0Domain: 'dev-bmttww5s.eu.auth0.com',
  auth0ClientId: 'ThcIBQZrRso5QaZq67kCU5eFYTfZwTSK',
  audience: 'https://api.usealto.com',
  slackAuthorization: 'https://staging-alto-slack-app-cinunhosha-od.a.run.app/slack/install?companyId=',
  applicationId: '5de36fd6-36c0-49ac-97bc-9e0517527d1c',
  googleChatAppURL: 'https://workspace.google.com/marketplace/app/alto/1076506336192',
  teamsAppUrl: 'https://appsource.microsoft.com/product/office/WA200006872',
  sentryDsn: 'https://1a9f0e07b9404c1ced5df9b98d821209@o4506417447174144.ingest.sentry.io/4506558380048384',
  sentryTracesSampleRate: 1.0,
  sentryReplaysSessionSampleRate: 0,
  sentryReplaysOnErrorSampleRate: 1.0,
  sentryEnvironment: 'development',
  crispWebsiteId: 'ab707f9e-f835-4dac-b063-dd1fd3153f7f',
};
